import React, { useState } from "react";
import useSWR from "swr";
import { mutate } from "swr";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Snackbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress as Loader,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function StudentsList() {
  const { data, error } = useSWR(
    "https://layapriya.bringecommerce.site/api/layapriya/registration-details-v2/get-student-data",
    fetcher
  );

  const [activeStudent, setActiveStudent] = useState(null); // For handling active student actions
  const [anchorEl, setAnchorEl] = useState(null); // To position popup next to the 3 dots
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalType, setModalType] = useState(null); // To handle which modal to open
  const [loader, setLoader] = useState(false); // To handle loader in generate link

  const [feeAmount, setFeeAmount] = useState(""); // For Update Fees modal
  const [currentStudentId, setCurrentStudentId] = useState("");
  const [generatedLink, setGeneratedLink] = useState(""); // For Generate Payment Link

  const handleActionClick = (student, event) => {
    setCurrentStudentId(student._id);
    setActiveStudent(student);
    setAnchorEl(event.currentTarget);
    setGeneratedLink("");
    // Set the feeAmount to the student's existing amount for the update modal
    if (student.amount) {
      setFeeAmount(student.amount); // Preset feeAmount to the student's current fee
    }
  };

  const handleClosePopup = () => {
    setActiveStudent(null);
    setAnchorEl(null);
  };

  const handleUpdateFees = async () => {
    try {
      const response = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya-v2/update-fees",
        {
          _id: currentStudentId,
          amount: feeAmount,
        }
      );
      // Simulate API call for updating fees
      if (response.data.status === "SUCCESS") {
        setSnackbarMessage(`Fees updated for ${response?.data?.message}`);
        setSnackbarOpen(true);
        setModalType(null);

        // Update the cached student data using SWR's mutate
        mutate(
          "https://layapriya.bringecommerce.site/api/layapriya/registration-details-v2/get-student-data",
          (cachedData) => {
            return {
              ...cachedData,
              data: cachedData.data.map((student) =>
                student._id === currentStudentId
                  ? { ...student, amount: feeAmount } // Update the fee amount in local data
                  : student
              ),
            };
          },
          false // False to avoid re-fetching from the API
        );
      } else {
        setSnackbarMessage("Internal server error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to update fees");
      setSnackbarOpen(true);
    }
  };

  const handleGenerateLink = async () => {
    setLoader(true);
    try {
      let apiCallForPaymentLink = await axios.post(
        `https://layapriya.bringecommerce.site/api/checkout-stripe/${activeStudent._id}`,
        {
          amount: activeStudent.amount,
        }
      );

      if (apiCallForPaymentLink?.data?.statusCode === 202) {
        const paymentLink = apiCallForPaymentLink?.data?.url; // Fetch the payment link from API
        setGeneratedLink(paymentLink);
      } else {
        setSnackbarMessage("Failed to generate payment link");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error occurred while generating link");
      setSnackbarOpen(true);
    } finally {
      setLoader(false); // Ensure loader stops after process completes
    }
  };

  const handleBypassPayment = async () => {
    try {
      let apiCallForBypassPayment = await axios.get(
        `https://layapriya.bringecommerce.site/api/layapriya-v2/by-pass-payment/${activeStudent._id}`
      );

      if (apiCallForBypassPayment.data.status === "SUCCESS") {
        setSnackbarMessage(`Payment bypassed for ${activeStudent.email}`);
        setSnackbarOpen(true);
        setModalType(null);

        // Mutate the SWR cache to update the payment status locally
        mutate(
          "https://layapriya.bringecommerce.site/api/layapriya/registration-details-v2/get-student-data",
          (cachedData) => {
            return {
              ...cachedData,
              data: cachedData.data.map((student) =>
                student._id === activeStudent._id
                  ? { ...student, paid: true } // Update the 'paid' status to true
                  : student
              ),
            };
          },
          false // False to avoid re-fetching from the API
        );
      } else {
        setSnackbarMessage("Failed to bypass payment");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error bypassing payment:", error);
      setSnackbarMessage("Error occurred while bypassing payment");
      setSnackbarOpen(true);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink);
    setSnackbarMessage("Link copied to clipboard");
    setSnackbarOpen(true);
  };

  if (error)
    return (
      <Typography variant="h6" color="error">
        Failed to load data
      </Typography>
    );
  if (!data)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  if (data.data.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <Typography variant="h6">No registered students.</Typography>
      </Box>
    );
  }

  const students = data.data;

  return (
    <Box sx={{ p: 3, backgroundColor: "#f4f6f8", minHeight: "100vh" }}>
      <Typography
        variant="h4"
        sx={{
          mb: 3,
          textAlign: "center",
          fontWeight: "bold",
          color: "#3f51b5",
        }}
      >
        Students Registration List
      </Typography>
      <TableContainer component={Paper} elevation={6} sx={{ borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Payment Status</StyledTableCell>
              <StyledTableCell align="left">Fee Amount</StyledTableCell>
              <StyledTableCell align="left">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <StyledTableRow key={student._id}>
                <TableCell align="left">{student.email}</TableCell>
                <TableCell align="left">
                  <Typography
                    color={student.paid ? "success.main" : "error.main"}
                  >
                    {student.paid ? "Paid" : "Unpaid"}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {student.amount || "Yet to calculate"}
                </TableCell>

                <TableCell align="left">
                  {!student.paid && <IconButton
                    onClick={(event) => handleActionClick(student, event)}
                  >
                    <MoreVertIcon />
                  </IconButton>
}

                  {activeStudent === student && (
                    <ClickAwayListener onClickAway={handleClosePopup}>
                      <Box
                        sx={{
                          position: "absolute",
                          backgroundColor: "#fff",
                          borderRadius: 1,
                          boxShadow: 3,
                          mt: 1,
                          zIndex: 10,
                          minWidth: "150px",
                          p: 1,
                          border: "1px solid #ddd",
                        }}
                        anchorEl={anchorEl}
                      >
                        <Button
                          variant="text"
                          fullWidth
                          onClick={() => setModalType("updateFees")}
                        >
                          Update Fees
                        </Button>
                        <Button
                          variant="text"
                          fullWidth
                          onClick={() => setModalType("generateLink")}
                        >
                          Generate Payment Link
                        </Button>
                        <Button
                          variant="text"
                          fullWidth
                          onClick={() => window.open(`mailto:${student.email}`)}
                        >
                          Email Student
                        </Button>
                        {/* Conditionally render Bypass Payment only if payment is not made */}
                        {!student.paid && (
                          <Button
                            variant="text"
                            fullWidth
                            onClick={() => setModalType("bypassPayment")}
                          >
                            Bypass Payment
                          </Button>
                        )}
                      </Box>
                    </ClickAwayListener>
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />

      {/* Modals for actions */}
      <Dialog open={modalType !== null} onClose={() => setModalType(null)}>
        {modalType === "updateFees" && (
          <>
            <DialogTitle>Update Fees for {activeStudent?.email}</DialogTitle>
            <DialogContent>
              <TextField
                label="Enter New Fee Amount"
                fullWidth
                value={feeAmount} // This will be pre-filled with the student's current fee
                onChange={(e) => setFeeAmount(e.target.value)}
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleUpdateFees()}>Update</Button>
              <Button onClick={() => setModalType(null)}>Cancel</Button>
            </DialogActions>
          </>
        )}

        {modalType === "generateLink" && (
          <>
            <DialogTitle>
              Generate Payment Link for {activeStudent?.email}
            </DialogTitle>
            <DialogContent>
              {loader ? (
                <Loader sx={{ display: "block", mx: "auto" }} />
              ) : generatedLink ? (
                <>
                  <Typography sx={{ mt: 2 }}>{generatedLink}</Typography>
                  <Button
                    variant="outlined"
                    startIcon={<ContentCopyIcon />}
                    onClick={handleCopyLink}
                    sx={{ mt: 2 }}
                  >
                    Copy Link
                  </Button>
                </>
              ) : (
                <Button variant="contained" onClick={handleGenerateLink}>
                  Generate Link
                </Button>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setModalType(null)}>Close</Button>
            </DialogActions>
          </>
        )}

        {modalType === "bypassPayment" && (
          <>
            <DialogTitle>Bypass Payment for {activeStudent?.email}</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to bypass the payment?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleBypassPayment}>Confirm</Button>
              <Button onClick={() => setModalType(null)}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
