import React from "react";
import "./GuruProfile.css";
import { useNavigate } from "react-router-dom";

export default function GuruHome (){
    const navigate = useNavigate();
    return(
        <>
            <div>
    <button class="btn" style={{background:"violet"}} onClick={() => navigate("/gurus")}><i class="animation"></i>Gurus<i class="animation"></i>
    </button>
        <br/>
        <br/>
        <br/>
    <button class="btn" onClick={() => navigate("/tutors")}><i class="animation"></i>Tutors<i class="animation"></i>
    </button>
</div>
<br/>
        </>
    )
}