import React, { useState } from 'react';
import { Container, TextField, Button, Grid, Typography, Box, Alert } from '@mui/material';
import { Send } from '@mui/icons-material';
import axios from 'axios';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    // Construct payload to send to the API
    const payload = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
      createdAt: new Date()
    };

    try {
      // Send form data to the API
      const response = await axios.post('https://layapriya.bringecommerce.site/api/layapriya/form-fill-up', payload);

      if (response.status === 200) {
        setSuccessMessage('Thank you for contacting us! We will get back to you soon.');
        setFormData({ name: '', email: '', message: '' }); // Reset form on success
      }
    } catch (error) {
      setErrorMessage('There was an error submitting the form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: '2rem', padding: '2rem', boxShadow: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Message"
              name="message"
              variant="outlined"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              endIcon={<Send />}
              disabled={isLoading}
              sx={{ marginTop: '1rem', padding: '0.5rem 2rem' }}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {successMessage && (
        <Alert severity="success" sx={{ marginTop: '1rem' }}>
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ marginTop: '1rem' }}>
          {errorMessage}
        </Alert>
      )}

      <Box sx={{ marginTop: '2rem', textAlign: 'center' }}>
        <Typography variant="body1" gutterBottom>
          Or Mail us directly at
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          href="mailto:layapriya@layapriya.ca"
          sx={{ padding: '0.5rem 2rem' }}
        >
          layapriya@layapriya.ca
        </Button>
      </Box>
    </Container>
  );
};

export default ContactUs;
