import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import TestPayment from "../../TestPayments/TestPayment";
import DotLottiePlayer from "../../GlobalComponents/DotLottiePlayer";
import axios from "axios";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import registration from "./media/registration.jpg";

const steps = ["Enter Email", "Verify PIN", "Success"];

const RegisterV2 = () => {
  const [formData, setFormData] = useState({ email: "", pin: "", payment: "" });
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [insertionId, setInsertionId] = useState("");
  const [canRequestOTP, setCanRequestOTP] = useState(true); // Timer state
  const [selectedOption, setSelectedOption] = useState("");
  const [dropdownError, setDropdownError] = useState(false);
  const isMobileDropdown = useMediaQuery("(max-width:600px)");

  const handleChangeDropdown = (event) => {
    setSelectedOption(event.target.value);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let timer;
    if (step === 1) {
      timer = setInterval(() => {
        checkOtpStatus();
      }, 60000); // Check every minute
    }
    return () => clearInterval(timer);
  }, [step]);

  const checkOtpStatus = async () => {
    try {
      const response = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya-v2/search-email",
        { email: formData.email }
      );
      const { status, timeRemaining } = response.data;

      if (status === "error") {
        setCanRequestOTP(false);
      } else {
        setCanRequestOTP(true);
      }
    } catch (error) {
      showSnackbar("Failed to check OTP status. Please try again.", "error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStep = async () => {
    if (step === 0 && validateEmail()) {
      await handleEmailValidation();
    } else if (step === 1 && validatePin()) {
      await handlePinVerification();
    } else if (step === 2 && validatePayment()) {
      handlePayment();
    }
  };

  // Email validation API call
  const handleEmailValidation = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya-v2/search-email",
        { email: formData.email }
      );
      const { status, message } = response.data;

      if (status === "success") {
        showSnackbar(message, "success");
        setStep(1); // Proceed to the next step
      } else {
        showSnackbar(message, "error");
      }
    } catch (error) {
      showSnackbar("Failed to validate email. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  // OTP (PIN) verification API call
  const handlePinVerification = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya-v2/otp-verify",
        { email: formData.email, otp: formData.pin }
      );
      const { status, message, _id } = response.data;

      if (status === "EMAIL_VERIFIED") {
        showSnackbar("OTP verified successfully!", "success");
        setInsertionId(_id);
        setStep(2);
      } else {
        showSnackbar(status, "error");
      }
    } catch (error) {
      showSnackbar("OTP verification failed. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  // Simulated Payment
  const handlePayment = async () => {
    setLoading(true);
    try {
      // Simulate a payment API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      showSnackbar("Payment successful! Registration complete.", "success");
      setStep(3);
    } catch (error) {
      showSnackbar("Payment failed. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      showSnackbar("Please enter a valid email address.", "error");
      return false;
    }
    return true;
  };

  const validatePin = () => {
    if (formData.pin.length !== 6) {
      showSnackbar("PIN must be 6 digits.", "error");
      return false;
    }
    return true;
  };

  const validatePayment = () => {
    if (formData.payment === "") {
      showSnackbar("Please complete payment to proceed.", "error");
      return false;
    }
    return true;
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const validateDropdown = () => {
    if (selectedOption === "") {
      setDropdownError(true);
      showSnackbar("Please select a class type.", "error");
      return false;
    }
    return true;
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <Box textAlign="center">
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{ mb: 3 }}
              style={{ fontWeight: "600" }}
            >
              Sign Up
            </Typography>
            <div
              style={{
                padding: "2px",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <p>
                The Laya Priya School of Performing Arts, Vancouver, is a
                sanctum dedicated to preserving the intricate fabric of South
                Indian Classical Karnatik Music and Rhythm. We are a dedicated
                collective of performers, scholars, and musicologists, united in
                our pursuit of excellence. Our mission is to ignite a passion
                for sound within the minds of aspiring musicians, nurturing
                their talents from early childhood and fostering a lifelong
                appreciation for this profound art form. <br />
                Website launching on 12th Oct 11.45 AM PDT
              </p>
            </div>
            <center>
              {/* <DotLottiePlayer
                src="https://lottie.host/a5ee8c90-0579-4fdb-a67d-92f6842bf9d4/9iAErXM7Np.json"
                style={{ width: "300px", height: "300px" }}
              /> */}
              <img
                src={registration}
                style={{ width: "240px", height: "300px", borderRadius: "8px" }}
              />
            </center>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
            />

            {/* <FormControl
              fullWidth={"100%"}
              variant="outlined"
              size="small"
              error={dropdownError}
              style={{width:"100%"}}
              sx={{
                textAlign: "left", // Aligns the text and label to the left
                marginTop: 2, // Adds some spacing for better UI
                width: isMobileDropdown ? '100%' : '60%', // Adjust width for desktop and mobile
              }}  
            >
              <InputLabel>Class Type</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleChangeDropdown}
                label="Class Type"
                
              >
                <MenuItem value="MridangamInPerson">
                  Mridangam – In-Person, Vancouver only
                </MenuItem>
                <MenuItem value="MridangamOnline">Mridangam – Online</MenuItem>
                <MenuItem value="MridangamHybrid">Mridangam – Hybrid</MenuItem>
                <MenuItem value="RhythmClasses">Rhythm Classes</MenuItem>
                <MenuItem value="KarnatikMusic">Karnatik Music</MenuItem>
                <MenuItem value="VocalOnline">Vocal - Online</MenuItem>
                <MenuItem value="FluteOnline">Flute - Online</MenuItem>
              </Select>
            </FormControl>
            <br /> */}
            <br />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNextStep}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Next"}
            </Button>

            <br />
            <br />
          </Box>
        );
      case 1:
        return (
          <Box textAlign="center">
            <Typography variant="h5" gutterBottom>
              Enter Your PIN
            </Typography>
            <TextField
              fullWidth
              label="PIN"
              name="pin"
              type="password"
              value={formData.pin}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNextStep}
              disabled={loading || !canRequestOTP}
            >
              {loading ? <CircularProgress size={24} /> : "Validate PIN"}
            </Button>
          </Box>
        );
      // case 2:
      //   return (
      //     <Box textAlign="center">
      //       <Typography variant="h5" gutterBottom>
      //         Redirecting you to payment
      //       </Typography>
      //       <TestPayment userId={insertionId} />
      //     </Box>
      //   );
      case 2:
        return (
          <Box textAlign="center">
            <Typography variant="h5" gutterBottom>
              Registration Successful!
            </Typography>
            <Typography variant="body1">
              Thank you for registering. You will receive a confirmation email
              shortly.
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box p={isMobile ? 2 : 4} maxWidth={600} margin="auto">
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent()}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RegisterV2;
