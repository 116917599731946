import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import 'aos/dist/aos.css';
// STUDENT
import Register from "./Auth/components/Register";
import Login from "./Auth/components/Login";
import Dashboard from "./Auth/components/Dashboard";
import HomePage from "./Global/HomePage";

// SUPER ADMIN
import SuperAdminHomePage from "./SuperAdmin/SuperAdminHomePage";
import PreProtectedSuperAdmin from "./SuperAdmin/PreProtectedSuperAdmin";
import StudentsList from "./SuperAdmin/SubLinks/StudentsList";
import AddNotifications from "./SuperAdmin/SubLinks/AddNotifications";
import MeetingDetails from "./SuperAdmin/MeetingDetails";
// import HomeAdmin from "./SuperAdmin/SubLinks/HomeAdmin";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MobileAuth from "./Auth/components/MobileAuth";
import TestPayment from "./TestPayments/TestPayment";
import SuccessStripe from "./TestPayments/SuccessStripe";
import FailedStripe from "./TestPayments/FailedStripe";
import ErrorPage from "./ErrorComponents/ErrorPage";
import RegisterV2 from "./Auth/components/RegisterV2";
import EnrolledStudents from "./SuperAdmin/EnrolledStudents";
import ScheduleClasses from "./SuperAdmin/SubLinks/ScheduleClasses";
import GuruProfile from "./Global/Guru/GuruProfile";
import Aos from "aos";
import TutorProfile from "./Global/Tutors/TutorProfile";

const theme = createTheme({
  typography: {
    fontFamily: '"SUSE", sans-serif',
  },
  components: {
    // Customize individual components if needed
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    // Add other components customization here
  },
});

const App = () => {
  useEffect(() => {
    Aos.init({
      duration : 2000
    });
  }, []);
  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthProvider>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/gurus" element={<GuruProfile />} />
              <Route exact path="/tutors" element={<TutorProfile />} />
              













              {/* ADMIN ROUTES */}
              <Route exact path="/super-admin" element={<PreProtectedSuperAdmin />} />
              <Route
                exact path="/super-admin-conquered"
                element={<SuperAdminHomePage />}
              >
                <Route exact path="students-list" element={<StudentsList />} />
                <Route exact path="enrolled-list" element={<EnrolledStudents />} />
                <Route exact path="schedule-classes" element={<ScheduleClasses />} />


                <Route
                  exact path="add-notifications"
                  element={<AddNotifications />}
                />
                <Route exact path="meet-details" element={<MeetingDetails />} />
              </Route>






              {/*STUDENT AUTH ROUTES  */}
              <Route exact path="/auth" element={<MobileAuth />} />
              <Route exact path="/register" element={<RegisterV2 />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/dashboard" element={<Dashboard />} />










              {/* PAYMENTS TEST */}
              <Route exact path="/payments/test" element={<TestPayment />} />
              <Route
                exact path="/payments/stripe-success/:sessionId/:userId"
                element={<SuccessStripe />}
              />
              <Route
                exact path="/payments/stripe-failed"
                element={<FailedStripe />}
              />

              
          {/* ERROR PAGE */}
          <Route path='*' element={<ErrorPage/>} />

            </Routes>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
