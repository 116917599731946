import React from 'react';
import './RegistrationOptions.css'; // Import your CSS file
import AOS from 'aos'; // Ensure AOS is installed and imported
import { useNavigate } from 'react-router-dom';

const RegistrationOptions = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="registration-container" data-aos="fade-up">
      <h1 className="registration-title">Registration</h1>
      <div className="options-container">
        <div className="option-item" data-aos="zoom-in">
          <h3>Mridangam</h3>
          <p>In-person in Vancouver/Online/Hybrid</p>
        </div>
        <div className="option-item" data-aos="zoom-in" data-aos-delay="100">
          <h3>Rhythm</h3>
          <p>In-person in Vancouver/Online/Hybrid</p>
        </div>
        <div className="option-item" data-aos="zoom-in" data-aos-delay="200">
          <h3>Karnatik Vocal</h3>
          <p>Online</p>
        </div>
        <div className="option-item" data-aos="zoom-in" data-aos-delay="300">
          <h3>Karnatik Flute</h3>
          <p>Online</p>
        </div>

     
      </div>
      <button class="button"  onClick={() => navigate("/register")} >
   Register Now
  <div class="arrow">›</div>
</button>

    </div>
  );
};

export default RegistrationOptions;
