import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SuperAdminAppbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const links = [
    {
      id: 1,
      title: 'Students Registrations List',
      href: 'students-list',
    },
    {
      id: 1,
      title: 'Enrolled Students',
      href: 'enrolled-list',
    },
    {
      id: 3,
      title: 'Layapriya Classes',
      href: 'meet-details',
    },
    {
      id: 4,
      title: 'Schedule Classes',
      href: 'schedule-classes',
    },
    {
      id: 2,
      title: 'Add Notifications',
      href: 'add-notifications',
    },
  ];

  const drawerItems = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Typography variant="h6" sx={{ p: 2, textAlign: 'center' }}>
        {/* Other  */}
      </Typography>
      <Divider />
      <List>
        {links.map((link) => (
          <ListItem button key={link.id} onClick={() => navigate(link.href)} aria-label={link.title} style={{cursor:'pointer'}}>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  function logout(){
    axios.get("https://layapriya.bringecommerce.site/api/layapriya/logout")
    .then((res) => {
      navigate("/super-admin")
    })
    .catch((Err) => {
      console.log(Err);
    })
  }

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#3f51b5' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Layapriya Governance Platform
          </Typography>
          <Button color="inherit" onClick={() => logout()}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerItems}
      </Drawer>
    </>
  );
};

export default SuperAdminAppbar;
