// DotLottiePlayer.js
import React, { useEffect, useRef } from 'react';
import '@dotlottie/player-component';

const DotLottiePlayer = ({ src, style }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    // Ensure the custom element is registered and available
    if (window.customElements.get('dotlottie-player')) {
      playerRef.current.src = src;
    }
  }, [src]);

  return (
    <dotlottie-player
      ref={playerRef}
      src={src}
      background="transparent"
      speed="1"
      style={style}
      loop
      autoplay
    ></dotlottie-player>
  );
};

export default DotLottiePlayer;
